






































































import { Component, Vue } from 'vue-property-decorator';
import {OfficeEditModel, OfficeViewModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";
import { appBaseUrl } from '@/appconfig';
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
  },
})
export default class Office extends Vue {
  loadingTrans = false;
  editModel: OfficeEditModel = new OfficeEditModel();
  viewModel: OfficeViewModel = new OfficeViewModel();
  showHint:boolean = false;

  get isInit(){
    return !this.viewModel.allCalendarsActive
        && !this.viewModel.phoneNumber
        && !this.viewModel.plz
        && !this.viewModel.address
        && !this.viewModel.city
  }

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.loading=true;
  }

  async mounted(){
    try{
      this.loading = true;
      this.editModel = await officeClient.getEditModel();
      this.viewModel = await officeClient.getViewModel();
      this.showHint=true;
    }finally{
      this.loading=false;
    }

  }

  copy(){
    let elem = this.$refs.link;
    //@ts-ignore
    elem.select();
    //@ts-ignore
    elem.setSelectionRange(0, 99999);
    document.execCommand("copy");
    notifySuccess("Kopiert")
  }

  get appBaseUrl(){
    return appBaseUrl;
  }

  async submit(){
    try{
      this.loadingTrans=true;
      await officeClient.update(this.editModel);
      this.editModel = await officeClient.getEditModel();
      this.viewModel = await officeClient.getViewModel();

      notifySuccess("Gespeichert")
    }finally{
      this.loadingTrans=false;
    }
  }
}
