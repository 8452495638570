































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  getDurationMinutesFromTimes,
  getMinutesFromTime,
  getOrDaysOfWeek,
  padZero,
  timeHourToInt
} from "@/utilities/helper";
import {DayOfWeek, TreatmentHourDayEditModel, TreatmentViewModel} from "@/api/generated-api";

interface WeekCalendarHour{
  Hour: string;
  IsMorningOrEvening: boolean;
}

@Component({
  components: {
  },
})

export default class WeekCalendar extends Vue {
  @Prop()treatmentHourDays!: TreatmentHourDayEditModel[];
  @Prop()treatments!: TreatmentViewModel[];

  get height(){
    return 400;
  }

  get hourHeight(){
    return this.height/12;
  }

  get daysOfWeek(){
    return getOrDaysOfWeek();
  }

  getTreatment(item: TreatmentHourDayEditModel){
    return this.treatments.find(x=>x.id == item.treatmentId)??{};
  }

  get minHour(){
    return Math.min(...this.treatmentHourDays.map(x=>timeHourToInt(x.startTime ?? '0')));
  }

  get maxHour(){
    return Math.max(...this.treatmentHourDays.map(x=>timeHourToInt(x.endTime ?? '23')));
  }

  getPixelHeight(item: TreatmentHourDayEditModel){
    let duration = getDurationMinutesFromTimes(item.startTime!, item.endTime!);
    return Math.ceil((duration/60)*this.hourHeight);
  }

  getPixelTop(item: TreatmentHourDayEditModel){
    let start = getMinutesFromTime(item.startTime!);

    return Math.ceil(start - (this.minHour*60))*this.hourHeight/60;
  }

  get hours(){
    let result: WeekCalendarHour[]=[];
    for(let i = this.minHour; i<=this.maxHour;i++){
      result.push({
        Hour: padZero(i)+':00',
        IsMorningOrEvening: i<8||i>17
      });
    }

    return result;
  }
}
