






























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {AppointmentViewModel} from "@/api/generated-api";

@Component({
  components: {
  },
})
export default class OfficeAppointmentRawTable extends Vue {
  @Prop()appointments!: AppointmentViewModel[];
  @Prop()isExport!: boolean;

  @Emit('edit')
  edit(appointment: AppointmentViewModel){
    return appointment;
  }

  get tdClass(){
    return this.isExport ? '' : 'truncate';
  }
}
