

















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class OrToggle extends Vue {
  @Prop() value!: boolean;
  @Prop() disabled!: boolean;
  @Prop({default: 'bg-green-500'}) bgClass!: string
  @Prop({default: 'bg-gray-300'}) falseBgClass!: string

  @Emit('input')
  toggle() {
    if(!this.disabled){
      return !this.value;
    }
  }
}
