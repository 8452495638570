









import { Component, Vue } from 'vue-property-decorator';
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {

  },
})
export default class App extends Vue {

  private default_layout = 'default';

  layout() {
    return (this.$route.meta.layout || this.default_layout) + '-layout';
  }

  get isRouterLoading(){
    return OfficeStore.routerLoading;
  }

}
