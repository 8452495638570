































import { Component, Vue } from 'vue-property-decorator';
import {AppointmentViewModel, TreatmentViewModel} from "@/api/generated-api";
import {patientAppointmentClient} from "@/api/clients";

@Component({
  components: {
  },
})
export default class PatientAppointmentConfirmation extends Vue {
  appointment: AppointmentViewModel = AppointmentViewModel.fromJS({});
  loading: boolean=true;

  guid: string = '';
  id: number = 0;

  created(){
    this.guid = this.$route.params.guid;
    this.id = parseInt(this.$route.params.id);
  }

  async mounted(){
    try{
      this.loading=true;
      this.appointment = await patientAppointmentClient.getBookedAppointment(this.guid, this.id);
    }finally{
      this.loading=false;
    }
  }

  get treatment():TreatmentViewModel{
    return this.appointment?.treatment ?? TreatmentViewModel.fromJS({});
  }

  get confirmationNeeded(){
    return true;
    return this.treatment.appointmentMustBeConfirmed && !this.appointment.isConfirmed;
  }

  get office(){
    return this.appointment?.office ?? {};
  }
}
