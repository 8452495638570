





























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  AppointmentEditModel,
  AppointmentSlot,
  OfficeCalendarDayModel,
  OfficeCalendarModel,
  TreatmentBlock
} from "@/api/generated-api";
import {dateSmaller, getUniqueValues} from "@/utilities/helper";
import {appointmentClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";
import AppointmentConfirm from "@/views/Appointment/AppointmentConfirm.vue";
import {DateTime} from "luxon";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
    AppointmentConfirm
  },
})
export default class OfficeAppointmentList extends Vue {
  @Prop() treatmentBlockIds?: string[];
  @Prop() officeCalendar!: OfficeCalendarModel;

  appointmentTypeFilter: string = 'all';

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  mounted(){
    if(this.isPast){
      this.appointmentTypeFilter = 'booked';
    }else{
      this.appointmentTypeFilter = 'all';
    }
  }

  get appointmentTypeOptions() {
    if(this.isPast){
      return[{id: 'booked', name: 'Gebuchte Termine'}];
    }

    return [
      {id: 'all', name: 'Alle Termine'},
      {id: 'open', name: 'Freie Termine'},
      {id: 'booked', name: 'Gebuchte Termine'},
      {id: 'pending', name: 'Zu bestätigende Termine'},
    ]
  }

  @Watch('treatmentBlocks')
  blocksChanged(){
    if(this.isPast){
      this.appointmentTypeFilter = 'booked';
    }else{
      this.appointmentTypeFilter = 'all';
    }
  }

  edit(id: number, slot: AppointmentSlot){
    this.$router.push({ name: 'OfficeAppointmentEdit', params:{id: id.toString(),
        datetime: slot.appointmentDateTime!,
        treatmentHourDayId: slot.treatmentHourDayId!.toString()}});
  }

  async confirmAppointment(model: AppointmentEditModel){
    try{
      this.loading=true;
      let editModel = await appointmentClient.getEditModel(model.id!);
      editModel.isConfirmed = true;
      editModel.isDeclined = false;
      await appointmentClient.update(editModel);
      notifySuccess('Termin bestätigt');
      this.$emit('reload');
    }finally{
      this.loading=false;
    }
  }

  get isPast(){
    return dateSmaller(DateTime.fromISO(this.treatmentBlocks?.[0]?.calendarDate ?? '2000-01-01'), OfficeStore.todayDt);
  }

  get hasData(){
    return !!this.treatmentBlocks.flatMap(x=>{
      return this.getAppointmentSlots(x);
    }).length;
  }

  get treatmentBlocks(): TreatmentBlock[]{
    return (this.officeCalendar.calendarDays?.flatMap(x=>x.treatmentBlocks ?? []) ??[])
        .filter(x=>this.treatmentBlockIds?.some(y=>y == x?.id)) ?? [];
  }

  get calendarDates(){
    return getUniqueValues(this.treatmentBlocks?.map(x=>x.calendarDate)??[]).sort((a,b)=>{return a>b ? 1 : -1});
  }

  getTreatmentBlocksPerDay(strDt: string){
    return this.treatmentBlocks?.filter(x=>x.calendarDate == strDt).sort((a,b)=>{return a.startTime!>b.startTime! ? 1 : -1});
  }

  getAppointmentSlots(block: TreatmentBlock) {
    let data = block.appointmentSlots ?? [];

    if (this.appointmentTypeFilter == 'all') {
      return data;
    }

    if (this.appointmentTypeFilter == 'open') {
      return data.filter(x => x.openAppointmentSlots);
    }

    if (this.appointmentTypeFilter == 'booked') {
      return data.filter(x => x.appointments?.some(y => y.isConfirmed));
    }

    if (this.appointmentTypeFilter == 'pending') {
      return data.filter(x => x.appointments?.some(y => y.confirmationPending));
    }

    return data;
  }

  getFilteredAppointments(slot: AppointmentSlot) {
    if (this.appointmentTypeFilter == 'open') {
      return [];
    }

    let data = slot.appointments ?? [];

    if (this.appointmentTypeFilter == 'booked') {
      return data.filter(x => x.isConfirmed);
    }

    if (this.appointmentTypeFilter == 'pending') {
      return data.filter(x => x.confirmationPending);
    }

    return data;
  }
}
