















































































































import { Component, Vue } from 'vue-property-decorator';
import OrSlideIn from "@/components/basic/OrSlideIn.vue";
import {DateTime} from "luxon";
import {AppointmentEditModel, TreatmentHourDayViewModel} from "@/api/generated-api";
import {appointmentClient, treatmentHourDayClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";
import {OfficeStore} from "@/store/OfficeStore";
import {dateSmaller, dateSmallerEqual} from "@/utilities/helper";

@Component({
  components: {
  },
})
export default class OfficeAppointmentEdit extends Vue {
  id: number = 0;
  treatmentHourDayId: number = 0;
  datetime: DateTime = OfficeStore.todayDt;
  treatmentHourDay: TreatmentHourDayViewModel = new TreatmentHourDayViewModel();
  editModel: AppointmentEditModel = new AppointmentEditModel();
  loading: boolean = true;
  loadingTrans = false;
  initialConfirmation: boolean = false;
  initialDeclined: boolean = false;

  async created(){
    this.id = parseInt(this.$route.params.id);
    this.treatmentHourDayId = parseInt(this.$route.params.treatmentHourDayId);
    this.datetime = DateTime.fromISO(this.$route.params.datetime);
  }

  async mounted(){
    this.openSlideIn();
    try{
      this.loading = true;
      this.treatmentHourDay = (await treatmentHourDayClient.getViewModels(this.treatmentHourDayId,
          null, null, null, null, null, null))?.[0];
      if(this.id == 0){
        this.editModel = await appointmentClient.getNewEditModel(this.datetime.toFormat('yyyy-MM-dd HH:mm:ss'), this.treatmentHourDayId);
        this.editModel.isConfirmed = true;
      }else{
        this.editModel = await appointmentClient.getEditModel(this.id);
      }
      this.initialConfirmation = !this.editModel.isConfirmed && !this.editModel.isDeclined;
      this.initialDeclined = !!this.editModel.isDeclined;
    }finally{
      this.loading = false;
    }
  }

  get isDisabled(){
    return this.isPast || this.initialDeclined;
  }

  get isPast(){
    return dateSmaller(DateTime.fromISO(this.editModel.appointmentDateTime??''), OfficeStore.todayDt);
  }

  get treatmentName(){
    return this.treatmentHourDay.treatment?.name;
  }

  async submit(){
    try{
      this.loadingTrans = true;
      if(this.id == 0){
        await appointmentClient.insert(this.editModel);
      }else{
        await appointmentClient.update(this.editModel);
      }
      notifySuccess("Gespeichert")
      this.closeSlideIn();
    }finally{
      this.loadingTrans = false;
    }
  }

  openSlideIn() {
    (this.$refs.slideIn as OrSlideIn).open();
  }

  closeSlideIn() {
    (this.$refs.slideIn as OrSlideIn).close();
  }

  slideOutClosed() {
    this.$router.back();
  }

}
