































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class OrInput extends Vue {
  @Prop() label!: string;
  @Prop() name!: string;
  @Prop() rules!: string;
  @Prop() value!: any;
  @Prop() inputClass!: string;
  @Prop() valImmediate!: boolean;
  @Prop() focus!: boolean;
  @Prop() isTextarea!: boolean;

  showIconSlot: boolean=true;

  mounted() {
    if (this.focus) (this.$refs.inputfield as HTMLInputElement).focus();
  }

  get showIconSlotReally(){
    return this.showIconSlot && !this.value;
  }

  focusEv(){
    this.showIconSlot = false;
  }

  @Emit('click')
  click(){
    return;
  }

  @Emit('input')
  input(event: any) {
    return event.currentTarget.value;
  }

  @Emit('blur')
  blur(event: any) {
    this.showIconSlot = true;
    return event;
  }
}
