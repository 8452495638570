







































import { Component, Vue } from 'vue-property-decorator';
import {CalendarViewModel} from "@/api/generated-api";
import {calendarClient} from "@/api/clients";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
  },
})
export default class CalendarList extends Vue {
  calendars:CalendarViewModel[]=[];
  showHint:boolean = false;

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.loading=true;
  }

  async mounted(){
    try{
      this.loading=true;
      await this.loadData();
      this.showHint=true;
    }finally{
      this.loading = false;
    }
  }

  async loadData(){
    this.calendars = await calendarClient.getViewModels(null,null, null, null, null);
  }

  async remove(id: number){
    try{
      this.loading=true;
      await calendarClient.delete(id);
      await this.loadData();
      await OfficeStore.reloadOfficeViewModel();
    }finally{
      this.loading=false;
    }
  }
  // getActiveTreatmentHourDays(treatment: TreatmentViewModel, dayOfWeek: DayOfWeek){
  //   return treatment.treatmentHours
  //       ?.find(x=>x.isActive)?.treatmentHourDays
  //       ?.filter(x=>x.dayOfWeek == dayOfWeek);
  // }

  // get daysOfWeek(){
  //   return getOrDaysOfWeek();
  // }
}
