




























































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  AppointmentFilter, AppointmentMultiDeclineModel,
  AppointmentSlot,
  AppointmentViewModel,
  CalendarViewModel,
  TreatmentViewModel
} from "@/api/generated-api";
import {appointmentClient, calendarClient, treatmentClient} from "@/api/clients";
import AppointmentConfirm from "@/views/Appointment/AppointmentConfirm.vue";
import {DateTime} from "luxon";
import {dateGreaterEqual, dateSmallerEqual, endOfMonth, endOfWeek} from "@/utilities/helper";
import {notifySuccess} from "@/utilities/notification";
import OfficeAppointmentRawTable from "@/views/Appointment/OfficeAppointmentRawTable.vue";
import {AppointmentStore} from "@/store/AppointmentStore";
import {OfficeStore} from "@/store/OfficeStore";
import qs from 'qs'
//@ts-ignore
import * as FileSaver from "file-saver";

Component.registerHooks(['beforeRouteUpdate']);

interface TableSearchModel{
  Prop: string;
  SearchTerm: string;
}

class PagingModel{
  NoOfRows: number = 0;
  RowsPerPage: number = 25;
  CurrentPage: number = 1;

  get NoOfPages(){
    if(!this.NoOfRows){
      return 1;
    }
    return Math.ceil(this.NoOfRows / this.RowsPerPage);
  }

  get fromIndex(){
    return (this.CurrentPage-1)*this.RowsPerPage;
  }

  get toIndex(){
    if(this.CurrentPage == this.NoOfPages){
      return this.NoOfRows-1;
    }

    return (this.CurrentPage*this.RowsPerPage)-1;
  }

  prevPage(){
    if(this.showPrevPage){
      this.CurrentPage-=1;
    }
  }

  nextPage(){
    if(this.showNextPage){
      this.CurrentPage+=1;
    }
  }

  get showPrevPage(){
    return this.CurrentPage > 1;
  }

  get showNextPage(){
    return this.CurrentPage < this.NoOfPages;
  }

  get rowPerPageOptions(){
    return [
      {id: 25, name: '25'},
      {id: 50, name: '50'},
      {id: 100, name: '100'},
      {id: 999999999, name: 'Alle'},
    ]
  }
}

@Component({
  components: {
    AppointmentConfirm, OfficeAppointmentRawTable
  },
})
export default class OfficeAppointmentTable extends Vue {
  appointments: AppointmentViewModel[]=[];
  calendars: CalendarViewModel[]=[];
  treatments: TreatmentViewModel[]=[];
  appointmentFilter: AppointmentFilter = AppointmentFilter.fromJS({});
  loadingTrans = false;
  appointmentType: string = 'all';
  searchModels: TableSearchModel[]=[
        {Prop: 'treatmentName', SearchTerm: ''},
        {Prop: 'firstName', SearchTerm: ''},
        {Prop: 'lastName', SearchTerm: ''},
        {Prop: 'comment', SearchTerm: ''},
        {Prop: 'email', SearchTerm: ''},
        {Prop: 'phoneNumber', SearchTerm: ''},
        {Prop: 'birthdateStr', SearchTerm: ''},
        {Prop: 'address', SearchTerm: ''},
        {Prop: 'plz', SearchTerm: ''},
        {Prop: 'city', SearchTerm: ''},
      ];
  throttleTimeout: any = null;
  throtteledSearchModels: TableSearchModel[] = [];
  dateSelectVal: string = 'all';
  initDateFrom: DateTime = OfficeStore.todayDt;
  dateFrom: DateTime = OfficeStore.todayDt;
  initDateTo: DateTime = OfficeStore.todayDt;
  dateTo: DateTime = OfficeStore.todayDt;
  pagingModel: PagingModel = new PagingModel();
  batchDeclineReason: string = '';

  queryCalendarId: string | null = null;
  queryTypeFilter: string | null = null;

  showHint:boolean = false;

  get hasAppointments(){
    return OfficeStore.officeViewModel.hasAppointments;
  }

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.loading=true;
    this.getQueryVals();
  }

  getQueryVals(){
    this.queryCalendarId = (this.$route.query.calendarid ?? null) as string;
    this.queryTypeFilter = (this.$route.query.typefilter ?? null) as string;
  }

  async beforeRouteUpdate(to: any, from: any, next: any) {
    if (to.name == 'OfficeAppointmentTable') {
      try{
        this.loading=true;
        await this.refreshAll();
      }finally{
        this.loading=false;
      }
      next();
    } else {
      next();
    }
  }

  @Watch('$route')
  async routeChanged(){
    let queryChanged = false;
    this.getQueryVals();
    if(this.calendars.length){
      if(this.queryCalendarId
          && this.appointmentFilter.calendarId != parseInt(this.queryCalendarId)){
        this.appointmentFilter.calendarId = parseInt(this.queryCalendarId);
        queryChanged =true;
      }
      if(this.queryTypeFilter && this.appointmentType != this.queryTypeFilter){
        this.appointmentType = this.queryTypeFilter;
        queryChanged=true;
      }
    }

    if(queryChanged){
      try{
        this.loading=true;
        await this.refreshAll();
      }finally{
        this.loading=false;
      }
    }
    this.loading=false;
  }

  async mounted(){
    try{
      this.loading=true;
      this.calendars = await calendarClient.getViewModels(null, null, null, null, null);
      this.appointmentFilter.calendarId = this.calendars?.[0].id;
      if(this.queryCalendarId){
        this.appointmentFilter.calendarId = parseInt(this.queryCalendarId);
      }
      if(this.queryTypeFilter){
        this.appointmentType = this.queryTypeFilter;
      }
      await this.refreshAll();
      this.showHint=true;
    }finally{
      this.loading=false;
    }
  }

  async refreshAllLoadingTrans(){
    try{
      this.loadingTrans = true;
      await this.refreshAll();
    }finally{
      this.loadingTrans = false;
    }
  }

  async refreshAll(){
    await this.$nextTick();
    await this.loadData();
    this.resetFiltersAndStuff();
  }

  resetFiltersAndStuff(){
    this.dateSelectVal = 'all';
    this.initDateFrom = OfficeStore.todayDt;
    this.dateFrom = OfficeStore.todayDt;
    this.initDateTo = DateTime.fromISO(this.appointments?.[this.appointments?.length -1]?.appointmentDateTime ?? OfficeStore.todayDt.toISO());
    this.dateTo = this.initDateTo;
    this.pagingModel.CurrentPage = 1;
    this.pagingModel.NoOfRows = this.appointments?.length ?? 0;
  }

  async loadData(){
    this.appointments = await appointmentClient.getViewModels(this.appointmentFilter);
    this.treatments = await treatmentClient.getViewModels(null, null,this.appointmentFilter.calendarId, null);
  }

  resetDates(){
    if(this.dateSelectVal != 'customRange' && this.dateSelectVal != 'customDate')
    this.dateFrom = this.initDateFrom;
    this.dateTo = this.initDateTo;
  }

  async exportTable(){
    try{
      this.loadingTrans=true;
      let result = await appointmentClient.getOfficeAppointmentsPdf(this.finalFilteredAppointments.map(x=>x.id!));
      FileSaver.saveAs(result.data, "Terminliste.pdf");
    }finally{
      this.loadingTrans=false;
    }

    // await this.$router.push('/appointmenttableexport/?'+qs.stringify({ids : this.finalFilteredAppointments.map(x=>x.id!)}, { arrayFormat: 'repeat' }));
  }

  getSearchTerm(prop: string){
    return this.searchModels.find(x=>x.Prop==prop)?.SearchTerm;
  }

  setSearchTerm(val: string, prop: string){
    const model = this.searchModels.find(x=>x.Prop==prop);
    if(model){
      this.searchModels.find(x=>x.Prop==prop)!.SearchTerm = val;

      if(prop=='treatmentName'){
        this.throtteledSearchModels = JSON.parse(JSON.stringify(this.searchModels));
      }

      if(this.throttleTimeout){
        clearTimeout(this.throttleTimeout);
      }
      this.throttleTimeout = setTimeout(()=>{
        this.throtteledSearchModels = JSON.parse(JSON.stringify(this.searchModels));
      },500);
    }
  }

  get pagedAppointments(){
    return this.appointments.filter((x, i)=>{return i>=this.pagingModel.fromIndex && i<=this.pagingModel.toIndex});
  }

  get dateFilteredAppointments(){
    return this.pagedAppointments.filter(x=>{
      let dt = DateTime.fromISO(x.appointmentDateTime!);

      return dateSmallerEqual(this.filterDateFrom, dt) && dateGreaterEqual(this.filterDateTo, dt);
    });
  }

  get searchedAppointments(){
    return this.dateFilteredAppointments.filter((x: any)=>{
      let res = true;
      for (let searchModel of this.throtteledSearchModels) {
        let inVal = x[searchModel.Prop]?.toString()?.toLowerCase() ?? '';
        if(!inVal && searchModel.SearchTerm){
          res = false;
        }
        if(inVal && searchModel.SearchTerm && inVal.indexOf(searchModel.SearchTerm.toLowerCase()) == -1){
          res = false;
        }
      }
      return res;
    });
  }

  get finalFilteredAppointments(){
    if(this.appointmentType == 'booked'){
      return this.searchedAppointments.filter(x=>x.isConfirmed && !x.isDeclined);
    }

    if(this.appointmentType == 'pending'){
      return this.searchedAppointments.filter(x=>x.confirmationPending);
    }
    if(this.appointmentType == 'declined'){
      return this.searchedAppointments.filter(x=>x.isDeclined);
    }

    return this.searchedAppointments;
  }

  get anyToConfirm(){
    return this.finalFilteredAppointments.some(x=>x.confirmationPending);
  }

  get anyBooked(){
    return this.finalFilteredAppointments.some(x=>x.isConfirmed && !x.isDeclined);
  }

  get appointmentTypeOptions() {
    return [
      {id: 'all', name: 'Alles anzeigen'},
      {id: 'booked', name: 'Gebuchte Termine'},
      {id: 'pending', name: 'Zu bestätigende Termine'},
      {id: 'declined', name: 'Abgelehnte Termine'},
    ]
  }

  edit(appointment: AppointmentViewModel){
    this.$router.push({ name: 'OfficeAppointmentTableEdit', params:{id: (appointment.id ?? 0).toString(),
        datetime: appointment.appointmentDateTime!,
        treatmentHourDayId: appointment.treatmentHourDayId!.toString()}});
  }

  async confirmAppointment(appointment: AppointmentViewModel){
    try{
      this.loading=true;
      let editModel = await appointmentClient.getEditModel(appointment.id!);
      editModel.isConfirmed = true;
      editModel.isDeclined = false;
      await appointmentClient.update(editModel);
      await OfficeStore.reloadOfficeViewModel();
      notifySuccess('Termin bestätigt');
      await this.loadData();
    }finally{
      this.loading=false;
    }
  }

  async confirmAll(){
    try{
      this.loading=true;
      let ids = this.finalFilteredAppointments.map(x=>x.id!);
      await appointmentClient.confirmMulti(ids);
      await OfficeStore.reloadOfficeViewModel();
      notifySuccess('Termine bestätigt');
      await this.loadData();
    }finally{
      this.loading=false;
    }
  }

  async cancelAll(){
    try{
      this.loading=true;
      let model: AppointmentMultiDeclineModel = new AppointmentMultiDeclineModel();
      model.declineReason = this.batchDeclineReason;
      model.appointmentIds = this.finalFilteredAppointments.map(x=>x.id!);
      await appointmentClient.declineMulti(model);
      await OfficeStore.reloadOfficeViewModel();
      notifySuccess('Termine abgesagt');
      this.batchDeclineReason='';
      await this.loadData();
    }finally{
      this.loading=false;
    }
  }

  ensureDateStr(dt: any){
    return dt.toString();
  }

  ensureDtFrom(dt:any){
    this.dateFrom = DateTime.fromFormat(dt.toString(),'yyyy-MM-dd');
  }

  ensureDtTo(dt:any){
    this.dateTo = DateTime.fromFormat(dt.toString(),'yyyy-MM-dd');
  }

  get treatmentOptions(){
    let res = this.treatments.map(x=>{
      return {
        id: x.name,
        name: x.name
      }
    });

    res.unshift({id: '', name: this.$t('Alle').toString()})

    return res;
  }

  get dateOptions(){
    return [
      {id: 'all', name: this.$t('Alle')},
      {id: 'currentWeek', name: this.$t('Diese Woche')},
      {id: 'nextWeek', name: this.$t('Nächste Woche')},
      {id: 'currentMonth', name: this.$t('Dieser Monat')},
      {id: 'nextMonth', name: this.$t('Nächster Monat')},
      {id: '30days', name: this.$t('Nächste 30 Tage')},
      {id: 'customRange', name: this.$t('Eigener Zeitraum')},
      {id: 'customDate', name: this.$t('Einzelner Tag')},
    ]
  }

  get filterDateFrom(){
    if(this.dateSelectVal == 'customDate'){
      return this.dateFrom;
    }

    if(this.dateSelectVal == 'customRange'){
      return this.dateFrom;
    }

    if(this.dateSelectVal == 'nextWeek'){
      return endOfWeek(OfficeStore.todayDt).plus({days: 1});
    }

    if(this.dateSelectVal == 'nextMonth'){
      return endOfMonth(OfficeStore.todayDt).plus({days: 1});
    }

    return this.dateFrom;
  }

  get filterDateTo(){
    if(this.dateSelectVal == 'customDate'){
      return this.dateFrom;
    }

    if(this.dateSelectVal == 'customRange'){
      return this.dateTo;
    }

    if(this.dateSelectVal == 'currentWeek'){
      return endOfWeek(this.filterDateFrom);
    }

    if(this.dateSelectVal == 'nextWeek'){
      return endOfWeek(this.filterDateFrom);
    }

    if(this.dateSelectVal == 'currentMonth'){
      return endOfMonth(this.filterDateFrom);
    }

    if(this.dateSelectVal == 'nextMonth'){
      return endOfMonth(this.filterDateFrom);
    }

    if(this.dateSelectVal == '30days'){
      return this.filterDateFrom.plus({days:30});
    }

    return this.dateTo;
  }

  get calendarOptions(){
    return this.calendars.map(x=>{
      return {
        id: x.id,
        name: x.name
      }
    });
  }
}
