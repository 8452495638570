













































import {Component, Vue, Watch} from 'vue-property-decorator';
import {AppointmentFilter, CalendarViewModel, OfficeCalendarModel, TreatmentBlock} from "@/api/generated-api";
import {appointmentClient, calendarClient} from "@/api/clients";
import {DateTime} from "luxon";
import {DateForEach, getGuid} from "@/utilities/helper";
import OfficeCalendar from "@/views/Appointment/OfficeCalendar.vue";
import OfficeAppointmentList from "@/views/Appointment/OfficeAppointmentList.vue";
import {OfficeStore} from "@/store/OfficeStore";

interface WeekCalendarHour{
  Hour: string;
  IsMorningOrEvening: boolean;
}

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    OfficeCalendar, OfficeAppointmentList
  },
})
export default class OfficeAppointment extends Vue {
  calendars: CalendarViewModel[]=[];
  appointmentFilter: AppointmentFilter = new AppointmentFilter();
  officeCalendar: OfficeCalendarModel = new OfficeCalendarModel();
  showHint:boolean = false;
  loadingTrans = false;

  selectedTreatmentBlockIds: string[]=[];
  queryCalendarId: string | null = null;

  getQueryVals(){
    this.queryCalendarId = (this.$route.query.calendarid ?? null) as string;
  }

  get hasAppointments(){
    return OfficeStore.officeViewModel.hasAppointments;
  }

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.getQueryVals();
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    if (from.name == 'OfficeAppointmentEdit') {
      try{
        this.loading=true;
        this.loadData();
      }finally{
        this.loading=false;
      }

      next();
    } else {
      next();
    }
  }

  @Watch('$route')
  async routeChanged(){
    let queryChanged = false;
    this.getQueryVals();

    if(this.calendars.length){
      if(this.queryCalendarId
          && this.appointmentFilter.calendarId != parseInt(this.queryCalendarId)){
        this.appointmentFilter.calendarId = parseInt(this.queryCalendarId);
        queryChanged =true;
      }
    }
    if(queryChanged){
      try{
        this.loading=true;
        await this.loadData();
      }finally{
        this.loading=false;
      }
    }
    this.loading=false;
  }

  async mounted(){
    try{
      this.loading=true;
      this.calendars = await calendarClient.getViewModels(null, null, null, null, null);
      if(this.queryCalendarId){
        this.appointmentFilter.calendarId = parseInt(this.queryCalendarId);
      }else{
        this.appointmentFilter.calendarId = this.calendars?.[0]?.id ?? 0;
      }
      if(this.appointmentFilter.calendarId>0){
        await this.loadData();
      }
      this.showHint=true;
    }finally{
      this.loading=false;
    }
  }

  get dateFrom(){
    return DateTime.fromISO(this.officeCalendar?.dateFrom??'2021-03-01T00:00:00');
  }

  get dateTo(){
    return DateTime.fromISO(this.officeCalendar?.dateTo??'2021-03-01T00:00:00');
  }

  async loadDataLoadingTrans(){
    try{
      this.loadingTrans = true;
      await this.loadData();
    }finally{
      this.loadingTrans=false;
    }
  }

  async loadData(){
    this.officeCalendar = await appointmentClient
        .getOfficeAppointments(this.appointmentFilter);
  }

  get eachDate(){
    return DateForEach(this.dateFrom, this.dateFrom.plus({days: 7}));
  }

  submit(){

  }
}
