import {ValidationProvider, ValidationObserver, extend, setInteractionMode, validate} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
//@ts-ignore
import { messages } from 'vee-validate/dist/locale/de.json';
import Vue from 'vue';

Object.keys(rules).forEach(rule => {
    extend(rule, {
        //@ts-ignore
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

extend('time-overlap', value => {
    return 'Überschneidung';
});

//@ts-ignore
extend('min-duration', {params: ['duration'], validate : (value, {duration}) =>{
    return 'Dauer mindestens {duration} min'
}});

//@ts-ignore
extend('block-duration-short', {params: ['rest'], validate : (value, {rest}) =>{
        return 'Dauer {rest} min zu kurz'
    }});

//@ts-ignore
extend('block-duration-long', {params: ['rest'], validate : (value, {rest}) =>{
        return 'Dauer {rest} min zu lang'
    }});


setInteractionMode('aggressive');

Vue.component('vv-provider', ValidationProvider);
Vue.component('vv-observer', ValidationObserver);
