import { vm } from '@/main';
import {i18n} from "@/localization";
// import { i18n } from '@/localization';

export function notifyError(messageTranslationKey: string) {
    vm.$toasted.error(i18n.t(messageTranslationKey).toString());
}

export function notifyWarning(messageTranslationKey: string) {
    vm.$toasted.info(i18n.t(messageTranslationKey).toString());
}

export function notifyDeletion(undoFunction: Function) {
    // vm.$toasted.show('Erfolgreich gelöscht', {
    //     duration: 8000,
    //     type: 'success',
    //     icon:'fa-trash',
    //     action : {
    //         text : 'Rückgängig',
    //         class: 'myaction',
    //         onClick : (e, toastObject) => {
    //             undoFunction();
    //             toastObject.goAway(0);
    //         }
    //     }
    // });
}

export function notifySuccess(messageTranslationKey: string) {
    vm.$toasted.show(i18n.t(messageTranslationKey).toString(), {
        type: 'success',
        icon:'fa-check'
    });
}
