
















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class OrDeleteButton extends Vue {
  @Prop()buttonClass!:string;

  open:boolean = false;

  @Emit('confirm')
  confirm(){
    return;
  }
}
