



















































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {DateTime} from "luxon";
import {
  CalendarEditModel,
  DayOfWeek,
  TreatmentHourDayEditModel,
  TreatmentHourEditModel,
  TreatmentViewModel
} from "@/api/generated-api";
import {dateGreaterEqual, dateSmallerEqual, getOrDaysOfWeek} from "@/utilities/helper";
import {calendarClient, treatmentClient, treatmentHourClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";
import TreatmentHourDayTimeEdit from "@/views/Calendar/TreatmentHourDayTimeEdit.vue";
import WeekCalendar from "@/views/CalendarComponents/WeekCalendar.vue";
import PatientSelectorPreview from "@/views/CalendarComponents/PatientSelectorPreview.vue";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
    TreatmentHourDayTimeEdit, WeekCalendar, PatientSelectorPreview
  },
})
export default class CalendarEdit extends Vue {
  loadingTrans = false;
  calendar: CalendarEditModel = new CalendarEditModel({treatmentHours: []});
  treatments: TreatmentViewModel[] = [];
  id: number = 0;
  showHint:boolean = false;
  showHintTimes:boolean = false;

  get isFirstCalendar(){
    return !OfficeStore.officeViewModel.showAppointmentPages;
  }

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  get daysOfWeek() {
    return getOrDaysOfWeek();
  }

  created(){
    this.loading=true;
  }

  async mounted() {
    try {
      this.loading = true;
      await this.loadData();
      this.showHint=true;
      this.showHintTimes=true;
    } finally {
      this.loading = false;
    }
  }

  async loadData() {
    this.id = parseInt(this.$route.params.id);

    this.treatments = await treatmentClient.getViewModels(null,null,null, null);

    if (!this.id) {
      this.calendar = await calendarClient.getNewEditModel();
    } else {
      this.calendar = await calendarClient.getEditModel(this.id);
    }
  }

  cancel() {
    this.$router.push({name: 'Calendar'});
  }

  async submit() {
    try {
      this.loadingTrans = true;
      if (!this.id) {
        this.id = await calendarClient.insert(this.calendar);
        this.calendar.id = this.id;
      } else {
        await calendarClient.update(this.calendar);
      }
      notifySuccess("Gespeichert");
      await this.$router.push({name: 'Calendar'});
    } finally {
      this.loadingTrans = false;
    }
  }

  async removeHours(index: number) {
    this.calendar.treatmentHours?.splice(index, 1);
  }

  showDateTo(date: string) {
    return (DateTime.fromISO(date).year < 2100) && ((this.calendar?.treatmentHours?.length ?? 0)>1);
  }

  showDateFrom(date:string){
    return DateTime.fromISO(date).year > 1900 && (this.calendar?.treatmentHours?.length ?? 0)>1;
  }

  get showDifferentTimesButton(){
    return !this.calendar?.treatmentHours?.some(x=>x.id == 0);
  }

  isDateGap(dateFrom: string, dateTo: string) {
    let dtFrom = DateTime.fromISO(dateFrom)
    let dtTo = DateTime.fromISO(dateTo)

    if (dtFrom.plus({'days': 1}) < dtTo) {
      return true;
    }

    return false;
  }


  getNextValidFromDate(index: number) {
    let validFrom = this.calendar.treatmentHours?.[index + 1]?.validFrom;

    if (validFrom) {
      return DateTime.fromISO(validFrom).toISODate();
    }
    return;
  }

  getValidFromMinDate(index: number) {
    let validTo = this.calendar.treatmentHours?.[index - 1]?.validTo;

    if (validTo) {
      return DateTime.fromISO(validTo).plus({'days': 1}).toISODate();
    }
    return;
  }

  getValidToMaxDate(index: number) {
    let validFrom = this.calendar.treatmentHours?.[index + 1]?.validFrom;

    if (validFrom) {
      return DateTime.fromISO(validFrom).minus({'days': 1}).toISODate();
    }
    return;
  }

  async addHours() {
    if (!this.calendar.id) {
      return;
    }

    let newHours = await treatmentHourClient.getNewEditModelForCalendar(this.calendar.id!);

    if (this.calendar.treatmentHours?.length) {
      let currentHours = this.calendar.treatmentHours![this.calendar.treatmentHours!.length - 1];
      currentHours.validTo = DateTime.fromISO(newHours.validFrom!).minus({'days': 1}).set({
        hour: 0,
        minute: 0,
        second: 0
      }).toISODate()
    }

    this.calendar.treatmentHours?.forEach(x => {
      x.isEditMode = false;
    })

    newHours.isEditMode = true;

    this.calendar.treatmentHours!.push(newHours);
  }

  // get durationPerPatient(){
  //   return parseInt((this.calendar.durationMinutes?.toString()??'0')) +parseInt((this.treatment.bufferBetweenTreatments?.toString()??'0'));
  // }

  remove(day: TreatmentHourDayEditModel, model: TreatmentHourEditModel) {
    model.treatmentHourDays?.splice(model.treatmentHourDays
        ?.findIndex(x => x.dayOfWeek == day.dayOfWeek && x.startTime == day.startTime), 1);
  }

  async add(model: TreatmentHourEditModel, dayOfWeek: DayOfWeek) {
    let days: TreatmentHourDayEditModel[] = model.treatmentHourDays?.filter(x => x.dayOfWeek == dayOfWeek) ?? [];

    let lastItemEndTime = days[days.length - 1]?.endTime ?? '08:00';

    let newItem = new TreatmentHourDayEditModel();

    newItem.dayOfWeek = dayOfWeek;
    newItem.startTime = lastItemEndTime;
    newItem.endTime = lastItemEndTime;

    model.treatmentHourDays?.push(newItem);
  }
}
