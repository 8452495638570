














































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  dateEqualIso,
  DateForEach,
  dateGreaterEqual, dateSmaller, dateSmallerEqual,
  getDurationMinutesFromTimes, getMinutesFromTime,
  getOrDaysOfWeek,
  padZero, startOfWeek,
  timeHourToInt
} from "@/utilities/helper";
import {
  AppointmentSlot,
  DayOfWeek,
  OfficeCalendarDayModel,
  OfficeCalendarModel, TreatmentBlock,
  TreatmentHourDayEditModel,
  TreatmentViewModel
} from "@/api/generated-api";
import {DateTime} from "luxon";
import {OfficeStore} from "@/store/OfficeStore";

interface WeekCalendarHour {
  Hour: string;
  IsMorningOrEvening: boolean;
}

@Component({
  components: {},
})

export default class OfficeCalendar extends Vue {
  @Prop() officeCalendar!: OfficeCalendarModel;
  @Prop() value?: string[];

  selectedDate: DateTime = OfficeStore.todayDt;

  // async created(){
  //   let unwatch = this.$watch('officeCalendar',()=>{
  //     this.selectedDateChanged();
  //     unwatch();
  //   });
  // }

  @Watch('officeCalendar')
  calendarChanged(){
    this.selectedDateChanged();
  }

  get selectedDateStr(){
    return this.selectedDate.toFormat('yyyy-MM-dd')+'T00:00:00';
  }

  set selectedDateStr(date: string){
    this.selectedDate = DateTime.fromISO(date);
  }

  @Watch('selectedDate')
  selectedDateChanged(){
    let allBlocks = this.officeCalendar?.calendarDays
        ?.find(x=>x.calendarDate == this.selectedDateStr)?.treatmentBlocks??[];

    this.$emit('input', allBlocks.map(x=>x.id));
  }

  get today(){
    return OfficeStore.todayDt;
  }

  get selectedMonday(){
    return startOfWeek(this.selectedDate);
  }

  get selectedTreatmentBlockIds(){
    return this.value??[];
  }

  isSelectedBlock(block: TreatmentBlock){
    return this.selectedTreatmentBlockIds.some(x=>x == block.id);
  }

  @Emit('input')
  async toggleBlock(block: TreatmentBlock){
    this.selectedDateStr = block.calendarDate!;
    await this.$nextTick();
    return [block.id];
  }

  switchWeek(noOfWeeks: number){
    this.selectedDate=this.selectedDate.plus({days:noOfWeeks*7});
  }

  get showLeftArrow(){
    return this.selectedDate.minus({days:7}) >= this.minDateFrom;
  }

  get showRightArrow(){
    return this.selectedDate.plus({days:7}) <= this.maxDateTo;
  }

  get officeCalendarWeek() {
    return this.officeCalendar.calendarDays
        ?.filter(x => dateGreaterEqual(DateTime.fromISO(x.calendarDate!), this.dateFrom)
            && dateSmallerEqual(DateTime.fromISO(x.calendarDate!), this.dateTo)) ?? [];
  }

  get height() {
    return 400;
  }

  get hourHeight() {
    return this.height / 12;
  }

  get minDateFrom() {
    return DateTime.fromISO(this.officeCalendar?.dateFrom ?? '1900-01-01T00:00:00');
  }

  get maxDateTo() {
    return DateTime.fromISO(this.officeCalendar?.dateTo ?? '2100-12-31T00:00:00');
  }

  get dateFrom() {
    return this.selectedMonday;
  }

  get dateTo() {
    return this.dateFrom.plus({days: 6});
  }

  dateIsPast(dt:DateTime){
    return dateSmaller(dt,this.today);
  }

  get eachDate() {
    return DateForEach(this.dateFrom, this.dateTo);
  }

  get minHour() {
    return timeHourToInt(this.officeCalendar?.minTime ?? '1') - 1;
  }

  get maxHour() {
    return timeHourToInt(this.officeCalendar?.maxTime ?? '22') + 1;
  }

  get hours() {
    let result: WeekCalendarHour[] = [];
    for (let i = this.minHour; i <= this.maxHour; i++) {
      result.push({
        Hour: padZero(i) + ':00',
        IsMorningOrEvening: i < 8 || i > 17
      });
    }

    return result;
  }

  getBookedAppointments(block: TreatmentBlock) : AppointmentSlot[]{
    return block.appointmentSlots?.filter(x=>(x.openAppointmentSlots ?? 0) <= 0) ?? [];
  }

  getBookedAppPixelHeight(appointment: AppointmentSlot){
    return Math.ceil((appointment.appointmentDurationMinutes! / 60) * this.hourHeight);
  }

  getBookedAppPixelTop(block: TreatmentBlock, appointment: AppointmentSlot){
    let blockStart = getMinutesFromTime(block.startTime!);
    let start = getMinutesFromTime(appointment.appointmentTime!);

    return Math.ceil(start - blockStart) * this.hourHeight / 60;
  }

  getTreatmentBlocks(dt: DateTime): TreatmentBlock[] {
    return this.officeCalendarWeek.find(x => dateEqualIso(x.calendarDate!, dt))?.treatmentBlocks ?? [];
  }

  getPixelHeight(block: TreatmentBlock) {
    let duration = getDurationMinutesFromTimes(block.startTime!, block.endTime!);
    return Math.ceil((duration / 60) * this.hourHeight);
  }

  getPixelTop(block: TreatmentBlock) {
    let start = getMinutesFromTime(block.startTime!);

    return Math.ceil(start - (this.minHour*60))*this.hourHeight/60;
  }
}
