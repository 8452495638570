






















































































import { Component, Vue } from 'vue-property-decorator';
import {DayOfWeek, TreatmentEditModel, TreatmentHourDayEditModel, TreatmentHourEditModel} from "@/api/generated-api";
import {treatmentClient, treatmentHourClient, treatmentHourDayClient} from "@/api/clients";
import {DateTime} from "luxon";
import TreatmentHourDayTimeEdit from "@/views/Calendar/TreatmentHourDayTimeEdit.vue";
import {notifySuccess} from "@/utilities/notification";
import WeekCalendar from "@/views/CalendarComponents/WeekCalendar.vue";
import {clone, dateGreaterEqual, dateSmallerEqual, getOrDaysOfWeek} from "@/utilities/helper";
import PatientSelectorPreview from "@/views/CalendarComponents/PatientSelectorPreview.vue";
import OrColorPicker from "@/components/basic/OrColorPicker.vue";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
    TreatmentHourDayTimeEdit, WeekCalendar, PatientSelectorPreview, OrColorPicker
  },
})
export default class TreatmentEdit extends Vue {
  loadingTrans = false;
  treatment:TreatmentEditModel= new TreatmentEditModel()
  id:number=0;
  showHint:boolean = false;

  get isFirstTreatment(){
    return !OfficeStore.officeViewModel.showCalendarPage;
  }

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.loading=true;
  }

  async mounted(){
    try{
      this.loading = true;
      await this.loadData();
      this.showHint=true;
    }finally{
      this.loading = false;
    }
  }

  async loadData(){
    this.id = parseInt(this.$route.params.id);

    if(!this.id){
      this.treatment = await treatmentClient.getNewEditModel();
      await OfficeStore.reloadOfficeViewModel();
      this.treatment.colorClass = OfficeStore.nextUnusedColor;
    }else{
      this.treatment = await treatmentClient.getEditModel(this.id);
    }
  }



  cancel(){
    this.$router.push({name: 'Treatment'});
  }

  async submit(){
    try{
      this.loadingTrans = true;
      if(!this.id){
        this.id = await treatmentClient.insert(this.treatment);
      }else{
        await treatmentClient.update(this.treatment);
      }
      notifySuccess("Gespeichert");
      await this.$router.push({name: 'Treatment'});
    }finally{
      this.loadingTrans = false;
    }
  }
}
