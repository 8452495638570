import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {setupAxios} from "@/axios-setup";
import {i18n} from '@/localization';
import './validation/index';
import Toasted from 'vue-toasted';
//@ts-ignore
import {Settings} from 'luxon';
import VTooltip, {VClosePopover} from 'v-tooltip';

import vSelect from 'vue-select';
Vue.component('v-select', vSelect)

import DefaultLayout from '@/layout/DefaultLayout.vue';
import './assets/tailwind.scss';
import '@/styles/main.scss';
import '@/utilities/filter';
import {frontendApiGeneratorClient} from "@/api/clients";
import OrInput from "@/components/basic/OrInput.vue";
import OrLoading from "@/components/basic/OrLoading.vue";
import UnauthorizedLayout from "@/layout/UnauthorizedLayout.vue";
import OrTimePicker from "@/components/basic/OrTimePicker.vue";

// @ts-ignore
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import OrDatePicker from "@/components/basic/OrDatePicker.vue";
import OrDeleteButton from "@/components/basic/OrDeleteButton.vue";
import OrSelect from "@/components/basic/OrSelect.vue";
import OrSlideIn from "@/components/basic/OrSlideIn.vue";
import OrToggle from "@/components/basic/OrToggle.vue";
import {OfficeStore} from "@/store/OfficeStore";
import ExportLayout from "@/layout/ExportLayout.vue";
import LandingLayout from "@/layout/LandingLayout.vue";

Vue.use(VueDatePicker);

// Options: You can set lang by default
Vue.use(VueDatePicker, {
  lang: 'de'
});

Vue.use(Toasted, {position: 'bottom-right', duration: 3000, iconPack: 'fontawesome'});

Vue.component('default-layout', DefaultLayout);
Vue.component('unauthorized-layout', UnauthorizedLayout);
Vue.component('landing-layout', LandingLayout);
Vue.component('export-layout', ExportLayout);
Vue.component('or-input', OrInput);
Vue.component('or-time-picker', OrTimePicker);
Vue.component('or-date-picker', OrDatePicker);
Vue.component('or-delete-button', OrDeleteButton);
Vue.component('or-loading', OrLoading);
Vue.component('or-select', OrSelect);
Vue.component('or-slide-in', OrSlideIn);
Vue.component('or-toggle', OrToggle);

Vue.use(VTooltip, {container: '#app'});
Vue.directive('v-close-popover', VClosePopover);

Vue.config.productionTip = false

export let vm: Vue;

async function startup() {

  Settings.defaultLocale = 'de';

  vm = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');

  OfficeStore.setIsRouterLoading(false);
}

setupAxios();

startup();

