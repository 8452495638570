import {DateTime} from "luxon";
import {Vue} from 'vue-property-decorator';

Vue.filter('formatDateTime',(value: any)=>{
    if (value) {
        const dt = DateTime.fromISO(value);
        return dt.toFormat('dd.MM.yyyy HH:mm');
    }
});

Vue.filter('formatDate',(value: any)=>{
    if (value) {
        const dt = DateTime.fromISO(value);
        return dt.toFormat('dd.MM.yyyy');
    }
});

Vue.filter('formatTime',(value: any)=>{
    if (value) {
        const dt = DateTime.fromISO(value);
        return dt.toFormat('HH:mm');
    }
});

Vue.filter('formatDateAddedDays',(value: any, days: number)=>{
    if (value) {
        const dt = DateTime.fromISO(value).plus({'days': days});
        return dt.toFormat('dd.MM.yyyy');
    }
});
