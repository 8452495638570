
























import {Component, Prop, Vue} from 'vue-property-decorator';
import { ContentLoader } from 'vue-content-loader'

@Component({
  components: {
    ContentLoader
  },
})
export default class OrLoading extends Vue {
  @Prop()unauthorizedLayout!: boolean;
  @Prop()transparent!:boolean;
  @Prop()noLayout!:boolean;
  @Prop()absolute!:boolean;

  visible = true;
  timeoutVar: any = {};
  // mounted(){
  //   this.visible = false;
  //   this.timeoutVar = setTimeout(()=>{
  //     this.visible = true;
  //   },500);
  // }
  //
  // destroyed(){
  //   clearTimeout(this.timeoutVar);
  // }
}
