


































import {Component, Vue} from 'vue-property-decorator';
import {OfficeLoginModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";

@Component({
  components: {
  },
})
export default class Login extends Vue {
  private loginModel : OfficeLoginModel = new OfficeLoginModel();
  private loading: boolean = false;

  mounted(){
  }

  async submit() {
    this.loading = true;
    try{
      await officeClient.login(this.loginModel);
      this.loading = false;
      await this.$forceUpdate();
      await this.$nextTick();
      await this.$router.push({name: 'Office'});
    }finally{
      this.loading = false;
    }

  }
}
