import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Office from "@/views/Office/Office.vue";
import Login from "@/views/Auth/Login.vue";
import TreatmentList from "@/views/Treatment/TreatmentList.vue";
import TreatmentEdit from "@/views/Treatment/TreatmentEdit.vue";
import OfficeAppointment from "@/views/Appointment/OfficeAppointment.vue";
import CalendarList from "@/views/Calendar/CalendarList.vue";
import CalendarEdit from "@/views/Calendar/CalendarEdit.vue";
import OfficeAppointmentEdit from "@/views/Appointment/OfficeAppointmentEdit.vue";
import OfficeAppointmentTable from "@/views/Appointment/OfficeAppointmentTable.vue";
import PatientAppointment from "@/views/Patient/PatientAppointment.vue";
import OfficeAppointmentTableExport from "@/views/Appointment/OfficeAppointmentTableExport.vue";
import PatientAppointmentEdit from "@/views/Patient/PatientAppointmentEdit.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import PasswordResetSent from "@/views/Auth/PasswordResetSent.vue";
import SetForgottenPassword from "@/views/Auth/SetForgottenPassword.vue";
import Register from "@/views/Auth/Register.vue";
import PatientAppointmentConfirmation from "@/views/Patient/PatientAppointmentConfirmation.vue";
import ConfirmAccountNeeded from "@/views/Auth/ConfirmAccountNeeded.vue";
import ConfirmAccount from "@/views/Auth/ConfirmAccount.vue";
import NetworkError from "@/views/NetworkError.vue";
import NotFound from "@/views/NotFound.vue";
import {OfficeStore} from "@/store/OfficeStore";
import Landing from "@/views/Landing/Landing.vue";
import Impressum from "@/views/Landing/Impressum.vue";
import Datenschutz from "@/views/Landing/Datenschutz.vue";
import Agb from "@/views/Landing/Agb.vue";
import Unterauftragnehmer from "@/views/Landing/Unterauftragnehmer.vue";

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: { layout: 'landing', anonymous: true },
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
    meta: { layout: 'landing', anonymous: true },
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
    meta: { layout: 'landing', anonymous: true },
  },
  {
    path: '/unterauftragnehmer',
    name: 'Unterauftragnehmer',
    component: Unterauftragnehmer,
    meta: { layout: 'landing', anonymous: true },
  },
  {
    path: '/agb',
    name: 'Agb',
    component: Agb,
    meta: { layout: 'landing', anonymous: true },
  },
  {
    path: '/app',
    name: 'Office',
    component: Office
  },
  {
    path: '/networkerror',
    name: 'Networkerror',
    component: NetworkError,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: NotFound,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/register',
    name: 'Register',
    component: Register,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/confirmaccountneeded',
    name: 'ConfirmAccountNeeded',
    component: ConfirmAccountNeeded,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/confirmaccount',
    name: 'ConfirmAccount',
    component: ConfirmAccount,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/setforgottenpassword',
    name: 'SetForgottenPassword',
    component: SetForgottenPassword,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/passwordresetsent',
    name: 'PasswordResetSent',
    component: PasswordResetSent,
    meta: { layout: 'unauthorized', anonymous: true },
  },
  {
    path: '/app/officeappointment',
    name: 'OfficeAppointment',
    component: OfficeAppointment,
    children: [
      {
        path: '/app/officeappointment/edit/:id/:datetime/:treatmentHourDayId',
        name: 'OfficeAppointmentEdit',
        component: OfficeAppointmentEdit,
        meta: {routeloading: false}
      },
    ]
  },
  {
    path: '/app/appointmenttable',
    name: 'OfficeAppointmentTable',
    component: OfficeAppointmentTable,
    children: [
      {
        path: '/app/appointmenttable/edit/:id/:datetime/:treatmentHourDayId',
        name: 'OfficeAppointmentTableEdit',
        component: OfficeAppointmentEdit,
        meta: {routeloading: false}
      },
    ]
  },
  {
    path: '/app/appointmenttableexport',
    name: 'OfficeAppointmentTableExport',
    component: OfficeAppointmentTableExport,
    meta: { layout: 'export', anonymous: true, routeloading: false },
  },
  {
    path: '/app/appointment/:guid',
    name: 'Appointment',
    component: PatientAppointment,
    meta: { layout: 'unauthorized', anonymous: true, routeloading: false },
    children: [
      {
        path: '/app/appointment/:guid/edit/:datetime/:treatmentHourDayId',
        name: 'AppointmentEdit',
        component: PatientAppointmentEdit,
        meta: { layout: 'unauthorized', anonymous: true, routeloading: false },
      },
    ]
  },
  {
    path: '/app/appointmentconfirm/:guid/:id',
    name: 'AppointmentConfirm',
    component: PatientAppointmentConfirmation,
    meta: { layout: 'unauthorized', anonymous: true },
  }
  ,
  {
    path: '/app/calendar',
    name: 'Calendar',
    component: CalendarList,
  }
  ,
  {
    path: '/app/calendar/:id',
    name: 'CalendarEdit',
    component: CalendarEdit,
  },
  {
    path: '/app/treatment',
    name: 'Treatment',
    component: TreatmentList,
  }
  ,
  {
    path: '/app/treatment/:id',
    name: 'TreatmentEdit',
    component: TreatmentEdit,
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("@/views/NotFound.vue"),
    meta: { layout: 'unauthorized', anonymous: true },
  }
]

if(process.env.NODE_ENV === 'development'){
  routes.push(
      {
        // catch all 404 - define at the very end
        path: "/app/reloadapi",
        name: 'ReloadApi',
        component: () => import("@/views/ReloadApi.vue"),
      }
  )
}

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to: any, from: any, next: any) => {

  if(to.name != "Landing"){
    document.title = to.name;
  }

  if (!to.matched.some((record: any) => record.meta.anonymous)) {
    try {
      if (!to.matched.some((record: any) => record.meta.routeloading == false)
      && !from.matched.some((record: any) => record.meta.routeloading == false)){
        OfficeStore.setIsRouterLoading(true);
      }
      await OfficeStore.loadTodayDt();
      await OfficeStore.reloadOfficeViewModel();
      next();
    } catch (err) {
      next({
        path: '/app/login',
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});

router.afterEach(()=>{
  // setTimeout(()=>{OfficeStore.setIsRouterLoading(false);},500);
});

export default router
