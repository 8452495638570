


















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {getColorClasses} from "@/utilities/helper";

@Component({
  components: {
  },
})
export default class OrColorPicker extends Vue {
  @Prop()value!:string;

  @Emit('input')
  input(colorClass: string){
    return colorClass;
  }


  get colorClasses(){
    return getColorClasses();
  }
}
