





import { Component, Vue } from 'vue-property-decorator';
import {AppointmentStore} from "@/store/AppointmentStore";
import OfficeAppointmentRawTable from "@/views/Appointment/OfficeAppointmentRawTable.vue";
import {AppointmentViewModel} from "@/api/generated-api";
import qs from "qs";
import {appointmentClient} from "@/api/clients";

@Component({
  components: {
    OfficeAppointmentRawTable
  },
})
export default class OfficeAppointmentTableExport extends Vue {

  appointments: AppointmentViewModel[]=[];

  async created(){
    //@ts-ignore
    let ids: number[] = qs.parse(this.$route.query).ids;
    console.log(ids);
    this.appointments = await appointmentClient.getViewModelsByIds(ids);
  }
}
