







































import { Component, Prop, Vue } from 'vue-property-decorator';
import MainMenuItemLink from "@/layout/MainMenuItemLink.vue";
import {routes} from "@/router";

@Component({
  components: {
    MainMenuItemLink,
  },
})
export default class MainMenuItem extends Vue {
  private visible = false;
  @Prop() type!: string;
  @Prop() icon!: string;
  @Prop() to!: any;
  @Prop() activeGroup!: string;

  get isActive() {
    const currentRoutePath = this.$route.path??'';

    if (!this.to || !this.to.name)
      return false;

    const tagRoute = routes.find(x=>x.name == this.to.name) ?? {path:''};

    if (this.to.name == 'Office')
      return currentRoutePath == tagRoute.path;

    return currentRoutePath.toLowerCase().indexOf(tagRoute.path.toLowerCase()) != -1;
  }
}
