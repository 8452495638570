import {
    AppointmentClient,
    CalendarClient,
    // AppointmentClient,
    FrontendApiGeneratorClient,
    OfficeClient, PatientAppointmentClient,
    TreatmentClient,
    TreatmentHourClient,
    TreatmentHourDayClient
} from "@/api/generated-api";
import axios from "axios";
import {baseUrl} from "@/appconfig";

export const frontendApiGeneratorClient = new FrontendApiGeneratorClient(baseUrl, axios);
export const officeClient = new OfficeClient(baseUrl, axios);
export const treatmentClient = new TreatmentClient(baseUrl, axios);
export const treatmentHourClient = new TreatmentHourClient(baseUrl, axios);
export const treatmentHourDayClient = new TreatmentHourDayClient(baseUrl, axios);
export const calendarClient = new CalendarClient(baseUrl, axios);
export const appointmentClient = new AppointmentClient(baseUrl, axios);
export const patientAppointmentClient = new PatientAppointmentClient(baseUrl, axios);
