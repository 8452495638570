

























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class AppointmentConfirm extends Vue {

  @Prop({default: 'Bestätigen'}) tooltipText!: string;
  open:boolean = false;

  @Emit('confirmed')
  confirmed(){
    this.open=false;
    return
  }
}
