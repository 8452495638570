

































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {DateTime} from "luxon";

@Component({
  components: {
  },
})
export default class OrDatePicker extends Vue {
  @Prop()value!:any;
  @Prop()minDate!:string;
  @Prop()maxDate!:string;
  @Prop()inputClass!: string;
  @Prop()useLuxon!: boolean;
  @Prop()disabled!: boolean;
  @Prop()birthday!: boolean;
  @Prop()inline!:boolean;
  @Prop()allowedDates!:Function;
  menu: boolean = false;

  get displayDate(){
    return DateTime.fromISO(this.value).toFormat('dd.MM.yyyy');
  }

  get inputVal(){
    if(this.useLuxon){
      this.value.toISODate();
    }
    return this.value;
  }

  @Watch('menu')
  menuChanged(val:any){
    if(this.birthday){
      //@ts-ignore
      val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'));
    }
  }

  @Emit('input')
  input(val: string){
    if(this.useLuxon){
      return DateTime.fromISO(val);
    }
    return val;
  }
}
