































import {Component, Vue} from 'vue-property-decorator';
import {OfficeForgotPasswordModel, OfficeLoginModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";

@Component({
  components: {
  },
})
export default class SetForgottenPassword extends Vue {
  loading: boolean = false;
  model: OfficeForgotPasswordModel = OfficeForgotPasswordModel.fromJS({});

  created(){
    this.model.token = (this.$route.query.token ?? '') as string;
  }

  async submit() {
    this.loading = true;
    try{
      await officeClient.setForgotPassword(this.model);
      notifySuccess("Passwort gesetzt");
      setTimeout(()=>{
        this.$router.push({name: 'Login'})
      },1000);
    }finally{
      this.loading = false;
    }

  }
}
