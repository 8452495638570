






































import {Component, Vue} from 'vue-property-decorator';
import {OfficeLoginModel, OfficeRegisterModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";

@Component({
  components: {
  },
})
export default class Register extends Vue {
  private registerModel : OfficeRegisterModel = OfficeRegisterModel.fromJS({});
  private loading: boolean = false;

  mounted(){
  }

  async submit() {
    this.loading = true;
    try{
      await officeClient.register(this.registerModel);
      await this.$router.push({name: 'ConfirmAccountNeeded'});
    }finally{
      this.loading = false;
    }

  }
}
