




















































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {padZero} from "@/utilities/helper";
import {DateTime} from "luxon";

@Component({
  inheritAttrs: false,
  components: {

  },
})
export default class OrTimePicker extends Vue {
  @Prop()inputClass!:string;
  @Prop()value!:string;
  @Prop() rules!: string;
  @Prop() valImmediate!: boolean;
  @Prop() name!: string;

  isOpen=false;

  tempHourPart:string = '';
  tempMinutePart:string = '';

  hoursArray: Array<string>=['00','01','02','03','04','05', '06','07','08','09','10','11','12'
    ,'13','14','15','16','17','18','19','20','21','22','23'];
  minutesArray5: Array<string>=['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  minutesArray10: Array<string>=['00', '10', '20', '30', '40', '50'];
  minutesArray15: Array<string>=['00', '15', '30', '45'];
  minutesArray30: Array<string>=['00', '30'];

  @Watch('value')
  valueChanged(){
    this.tempMinutePart = this.minutePart;
    this.tempHourPart = this.hourPart;
  }

  mounted(){
    this.tempMinutePart = this.minutePart;
    this.tempHourPart = this.hourPart;
  }

  get minutes():Array<string>{
    return this.minutesArray5;
  }

  get hours(){
    return this.hoursArray;

  }

  hide(){
    this.$emit('closed');
    this.isOpen=false;
  }

  get tempHourMinuteString(){
    return this.tempHourPart+':'+this.tempMinutePart;
  }

  get hourPart(){
    if(!this.value){
      return '';
    }
    return this.value.substr(0,2);
  }

  set hourPart(val:string){
    if(!this.minutePart){
      this.tempMinutePart = this.minutes[0];
    }

    this.tempHourPart = val;

    this.input(this.tempHourMinuteString);
  }

  changeMinutes(interval: number){
    let minute = parseInt(this.minutePart, 10);

    minute += interval;

    if(minute > 59){
      minute = 59;
    }

    if(minute < 0){
      minute = 0;
    }

    this.minutePart=padZero(minute);
  }

  parseMinutePart(event:any){
    let strVal = event.target.value;
    let intVal = parseInt(strVal, 10);

    if(!intVal){
      this.minutePart='00';
      return;
    }

    if(intVal > 59){
      intVal = 59;
    }

    if(intVal < 0){
      intVal = 0;
    }

    this.minutePart=padZero(intVal);
  }

  get minutePart(){
    if(!this.value){
      return '';
    }
    return this.value.substr(3,2);
  }

  set minutePart(val:string){
    if(!this.hourPart){
      this.tempHourPart = this.hours[0];
    }

    this.tempMinutePart = val;

    this.input(this.tempHourMinuteString);
  }

  @Emit('input')
  input(val: string){
    return val;
  }

}
