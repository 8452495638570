





































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class LandingLayout extends Vue {
  get mainroute(){
    return this.$route.name == "Landing"
  }
}
