









import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OrSlideIn extends Vue {
  private isOpen: boolean = false;

  open() {
    setTimeout(() => {
      this.isOpen = true;
    }, 100);
  }

  close() {
    this.isOpen = false;
    setTimeout(() => {
      this.$emit('close');
    }, 200);
  }
}
