import {DateTime} from "luxon";
import {DayOfWeek} from "@/api/generated-api";


export function getColorClasses(){
    return [

        "#ac5e89",
        "#d2894c",
        "#73412d",
        "#d14546",
        "#53906f",
        "#6645b8",
        "#9b9941",
        "#6881b8",
        "#ca4cc2",
        "#65b74a",
        ]}

function onlyUnique(value:any, index:number, self:any) {
    return self.indexOf(value) === index;
}

export function startOfMonth(date: DateTime){
    return DateTime.fromFormat('1.' + date.toFormat('M.yyyy'), 'd.M.yyyy', {zone: 'utc'});
}

export function endOfMonth(date: DateTime){
    const firstOfMonth = startOfMonth(date);

    return firstOfMonth.plus({ months: 1 }).minus({days: 1});
}


export function endOfWeek(date: DateTime){
    return startOfWeek(date.plus({days: 7})).minus({days: 1});
}

export function getUniqueValues(data: Array<any>){
    return data.filter(onlyUnique);
}

export function startOfWeek(date: DateTime){
    let weekdayNumber = parseInt(date.toFormat('E'));
    weekdayNumber = weekdayNumber === 7 ? 0 : weekdayNumber;

    const startOfWeek = 1;
    const diff = (7 + (weekdayNumber - startOfWeek)) % 7;
    return date.minus({days: diff});
}

export function padZero(timeInt: number){
    return (timeInt < 10 ? '0' + timeInt.toString() : timeInt.toString());
}

export function DateForEach(dateFrom: DateTime, dateTo: DateTime){
    let res: DateTime[]=[];
    for(let start = dateFrom; start <= dateTo; start = start.plus({days: 1})){
        res.push(start);
    }
    return res;
}

export function getDurationMinutesFromTimes(timeFrom: string, timeTo: string){

    let hourMinutesFrom = getMinutesFromTime(timeFrom);
    let hourMinutesTo = getMinutesFromTime(timeTo);

    return hourMinutesTo - hourMinutesFrom;
    //
    // let dateFrom = DateTime.fromFormat('01.01.2000 '+timeFrom+':00', 'dd.MM.yyyy HH:mm:ss');
    // let dateTo = DateTime.fromFormat('01.01.2000 '+timeTo+':00', 'dd.MM.yyyy HH:mm:ss');
    // return dateTo.diff(dateFrom).milliseconds/60000;
}

export function getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// export function debounce (fn:any, delay:any) {
//     let timeoutID:any = null
//     return function () {
//         clearTimeout(timeoutID)
//         let args = arguments
//         //@ts-ignore
//         let that = this
//         timeoutID = setTimeout(function () {
//             fn.apply(that, args)
//         }, delay)
//     }
// }

export function floorTimeToHour(time:string, roundMinutes: number){
    let hours = timeHourToInt(time);
    let minutes = timeMinutesToInt(time);

    minutes = Math.floor((minutes/roundMinutes)*roundMinutes);

    if(minutes == 60){
        minutes = 0;
        hours += 1;
    }

    return padZero(hours)+':'+padZero(minutes);
}

export function ceilTimeToHour(time:string, roundMinutes: number){
    let hours = timeHourToInt(time);
    let minutes = timeMinutesToInt(time);

    minutes = Math.ceil((minutes/roundMinutes)*roundMinutes);

    if(minutes == 60){
        minutes = 0;
        hours += 1;
    }

    return padZero(hours)+':'+padZero(minutes);
}

export function roundTimeToHour(time:string, roundMinutes: number){
    let hours = timeHourToInt(time);
    let minutes = timeMinutesToInt(time);

    minutes = Math.round((minutes/roundMinutes)*roundMinutes);

    if(minutes == 60){
        minutes = 0;
        hours += 1;
    }

    return padZero(hours)+':'+padZero(minutes);
}

export function getTimeFromMinutes(minutes: number){
    return padZero(Math.floor(minutes/60))+':' + padZero(minutes%60);
}

export function getMinutesFromTime(time: string){
    return timeHourToInt(time)*60+timeMinutesToInt(time);
}

export function timeMinutesToInt(time:string){
    return parseInt(time.substr(3,2));
}

export function timeHourToInt(time:string){
    return parseInt(time.substr(0,2));
}

export function dateEqual(dateTime: DateTime, dateTime2: DateTime){
    return dateTime?.year === dateTime2?.year && dateTime?.month === dateTime2?.month && dateTime?.day === dateTime2?.day;
}

export function dateEqualIso(dateTimeIso: string, dateTime2: DateTime){
    return parseInt(dateTimeIso?.substr(0,4)) == dateTime2?.year
        && parseInt(dateTimeIso?.substr(5,2)) == dateTime2?.month
        && parseInt(dateTimeIso?.substr(8,2)) == dateTime2?.day
}

export function dateGreaterEqual(dateTime: DateTime, dateTime2: DateTime){
    if(dateTime?.year > dateTime2?.year){
        return true
    }else if(dateTime?.year == dateTime2?.year){
        if(dateTime?.month > dateTime2?.month){
            return true
        }else if(dateTime?.month == dateTime2?.month){
            if(dateTime?.day >= dateTime2?.day){
                return true;
            }
        }
    }

    return false;
}

export function dateSmallerEqual(dateTime: DateTime, dateTime2: DateTime){
    if(dateTime?.year < dateTime2?.year){
        return true
    }else if(dateTime?.year == dateTime2?.year){
        if(dateTime?.month < dateTime2?.month){
            return true
        }else if(dateTime?.month == dateTime2?.month){
            if(dateTime?.day <= dateTime2?.day){
                return true;
            }
        }
    }

    return false;
}

export function dateSmaller(dateTime: DateTime, dateTime2: DateTime){
    if(dateTime?.year < dateTime2?.year){
        return true
    }else if(dateTime?.year == dateTime2?.year){
        if(dateTime?.month < dateTime2?.month){
            return true
        }else if(dateTime?.month == dateTime2?.month){
            if(dateTime?.day < dateTime2?.day){
                return true;
            }
        }
    }

    return false;
}

export interface OrWeekDay{
    DayOfWeekEnum: DayOfWeek;
    DayOfWeekName: string;
    DayOfWeekNameShort: string;
}

export function getOrDaysOfWeek():OrWeekDay[]{
    return [
        {DayOfWeekEnum: DayOfWeek.Monday, DayOfWeekName: 'Montag', DayOfWeekNameShort: 'Mo'},
        {DayOfWeekEnum: DayOfWeek.Tuesday, DayOfWeekName: 'Dienstag', DayOfWeekNameShort: 'Di'},
        {DayOfWeekEnum: DayOfWeek.Wednesday, DayOfWeekName: 'Mittwoch', DayOfWeekNameShort: 'Mi'},
        {DayOfWeekEnum: DayOfWeek.Thursday, DayOfWeekName: 'Donnerstag', DayOfWeekNameShort: 'Do'},
        {DayOfWeekEnum: DayOfWeek.Friday, DayOfWeekName: 'Freitag', DayOfWeekNameShort: 'Fr'},
        {DayOfWeekEnum: DayOfWeek.Saturday, DayOfWeekName: 'Samstag', DayOfWeekNameShort: 'Sa'},
        {DayOfWeekEnum: DayOfWeek.Sunday, DayOfWeekName: 'Sonntag', DayOfWeekNameShort: 'So'},
    ]
}

export function clone(data:any){
    return JSON.parse(JSON.stringify(data));
}
