









































































































import { Component, Vue } from 'vue-property-decorator';
import {
  AppointmentFilter,
  CalendarViewModel,
  OfficeViewModel,
  PatientCalendarModel, PatientCalendarOpenAppointment, PatientCalendarTimeSlot,
  TreatmentViewModel
} from "@/api/generated-api";
import {
  appointmentClient,
  calendarClient,
  officeClient,
  patientAppointmentClient,
  treatmentClient
} from "@/api/clients";
import {DateTime} from "luxon";
import {dateEqual, dateEqualIso, DateForEach, endOfWeek, padZero, startOfWeek} from "@/utilities/helper";

interface WeekCalendarHour{
  Hour: string;
  HourInt: number;
  IsMorningOrEvening: boolean;
}

@Component({
  components: {
  },
})
export default class PatientAppointment extends Vue {
  loading:boolean = false;
  office: OfficeViewModel = OfficeViewModel.fromJS({});
  treatments: TreatmentViewModel[]=[];
  calendars: CalendarViewModel[]=[];
  appointmentFilter: AppointmentFilter = AppointmentFilter.fromJS({});
  patientCalendar: PatientCalendarModel = PatientCalendarModel.fromJS({});
  selectedDt: DateTime = DateTime.now();
  guid: string = '';

  get isPreviewMode(){
    return !this.office.allCalendarsActive && this.treatments.length;
  }

  created(){
    this.guid = this.$route.params.guid;
  }

  async mounted(){
    try{
      this.loading = true;
      this.office = await patientAppointmentClient.getOfficeViewModel(this.guid);
      if(!this.office){
        await this.$router.push({name: 'NotFound'});
      }
      this.treatments = this.office?.treatments??[];
    }finally{
      this.loading=false;
    }
  }

  async loadMinMaxDates(){
    let model = await patientAppointmentClient.getMinMaxDateForTreatment(this.guid, this.appointmentFilter.treatmentId!);
    this.appointmentFilter.dateFrom = model.minDate;
    this.appointmentFilter.dateTo = model.maxDate;
  }

  async loadCalendars(){
    try{
      this.loading = true;
      if(this.appointmentFilter.treatmentId){
        await this.loadMinMaxDates();
        this.calendars = await patientAppointmentClient.getCalendarsForTreatment(this.guid, this.appointmentFilter.treatmentId);
        this.appointmentFilter.calendarId = undefined;
        await this.loadPatientCalendar();
      }
    }finally{
      this.loading=false;
    }
  }

  async loadPatientCalendar(){
    try{
      this.loading=true;
      this.patientCalendar = await patientAppointmentClient.getPatientAppointments(this.guid, this.appointmentFilter);
      this.selectedDt = this.firstAppointmentDt;
    }finally{
      this.loading=false;
    }
  }

  get noOpenAppointments(){
    return !this.patientCalendar?.dayModels?.some(x=>x.dayHasOpenAppointments);
  }

  get treatmentOptions(){
    let res: any = [{id:null, name: 'Behandlung auswählen'}];

    this.treatments.forEach(x=>{
      res.push({id: x.id!, name: x.name!});
    });

    return res;
  }

  isSelectedDate(dt: DateTime){
    return dateEqual(this.selectedDt, dt);
  }

  openEdit(slot:PatientCalendarOpenAppointment){
    this.$router.push({ name: 'AppointmentEdit', params:{
        datetime: slot.appointmentDateTime!,
        treatmentHourDayId: slot.treatmentHourDayId!.toString()}});
  }

  allowedDates(date:string){
    let res = false;
    let inDt = DateTime.fromISO(date);

    this.patientCalendar?.dayModels?.filter(x=>x.dayHasOpenAppointments)?.forEach(x=>{
      if(dateEqualIso(x.calendarDate!,inDt)){
        res = true;
      }
    });

    return res;
  }

  get firstAppointmentDt(){
    return DateTime.fromISO(this.patientCalendar?.dayModels?.find(x=>{
      return x.dayHasOpenAppointments
    })?.calendarDate??'');
  }

  get multiCalendarSelection(){
    return !this.appointmentFilter.calendarId && (this.calendars?.length ?? 0) > 1;
  }

  get selectedCalendar(){
    let cal = this.calendars.find(x=>x.id == this.appointmentFilter.calendarId);

    if(!cal){
      return this.calendars?.[0]?.name;
    }

    return cal?.name;
  }

  get selectedWeekStart(){
    return startOfWeek(this.selectedDt);
  }

  get selectedWeekEnd(){
    return endOfWeek(this.selectedDt);
  }

  get weekCalDays(){
    return DateForEach(this.selectedWeekStart, this.selectedWeekEnd);
  }

  getHourAppointments(day: DateTime, hourInt: number):PatientCalendarTimeSlot[]{
    let calDay = this.patientCalendar.dayModels?.find(x=>dateEqualIso(x.calendarDate!, day));

    return calDay?.timeSlots?.filter(x=>x.startHourInt == hourInt)??[];
  }

  get hours(){
    let result: WeekCalendarHour[]=[];
    for(let i = this.patientCalendar.minHour!-1; i<=this.patientCalendar.maxHour!;i++){
      result.push({
        Hour: padZero(i)+':00',
        HourInt: i,
        IsMorningOrEvening: i<8||i>17
      });
    }

    return result;
  }

  get selectedTreatment(){
    return this.treatments.find(x=>x.id == this.appointmentFilter.treatmentId);
  }

  get calendarOptions(){
    let res: Array<any> = [
      {id: null, name: 'Alle'}
    ];

    this.calendars.forEach(x=>{
      res.push({id: x.id, name: x.name})
    });

    return res;
  }
}
