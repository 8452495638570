













import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  components: {
  },
})
export default class OrSelect extends Vue {
  @Prop()options!: any[];
  @Prop()value!: string;
  @Prop()labelProp!: string;
  @Prop()idProp!: string;
  @Prop()inputClass!:string;
  @Prop() rules!: string;
  @Prop()name!:string;

  getBgColor(option:any){
    if(option['colorClass']){
      return 'background-color: '+option['colorClass'];
    }
  }

  getTextClass(option: any){
    if(option['colorClass']){
      return 'text-gray-100';
    }
  }

  @Emit('input')
  input(event: any){
    return event.currentTarget.value;
  }
}
