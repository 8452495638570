import router from '@/router';
import axios , { AxiosRequestConfig } from 'axios';
import {notifyError} from "@/utilities/notification";
import {OfficeStore} from "@/store/OfficeStore";

export function setupAxios(){
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use((config: AxiosRequestConfig) => {

        const header: any = {};

        header['Accept-Language'] = 'de';
        header['Content-Type'] = 'application/json';
        header['Pragma'] = 'no-cache';
        config.headers = header;
        return config;
    }, (err) => {
        return Promise.reject(err)
    });

    axios.interceptors.response.use((resp) => {
        return resp;
    }, async (error) => {

        // if (error.message === 'Network Error') {
        //     await router.push('/networkerror');
        // }

        if (error.response && error.response.status === 401) {
            await router.push({name: 'Login'});
        }

        else if (error.response && error.response.status === 404) {
            await router.push({name: 'NotFound'});
        }

        else if(error.response && error.response.status === 400){
            notifyError(error.response.data);
            await router.push({name: 'Networkerror'});
        }

        else if(error.response && error.response.status === 403){
            notifyError("Zugriff verweigert");
            await router.push({name: 'Login'});
        }

        else if(error.response && error.response.status === 406){
            notifyError(error.response.data.Message);
        }

        else if(error.response && error.response.status === 500){
            notifyError('Unerwarteter Fehler');
            await router.push({name: 'Networkerror'});
        }else{
            await router.push({name: 'Networkerror'});
        }

        OfficeStore.setIsRouterLoading(false);

        return Promise.reject(error);
    });
}
