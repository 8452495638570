
















import {Component, Prop, Vue} from 'vue-property-decorator';
import MainMenuItem from "@/layout/MainMenuItem.vue";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
    MainMenuItem
  },
})
export default class MainMenu extends Vue {

  get showCalendarPage(){
    return OfficeStore.officeViewModel.showCalendarPage;
  }

  get onTreatmentPage(){
    return this.$route.name == 'Treatment' || this.$route.name == 'TreatmentEdit'
  }

  get onCalendarPage(){
    return this.$route.name == 'Calendar' || this.$route.name == 'CalendarEdit'
  }

  get showAppointmentPages(){
    if(!OfficeStore.officeViewModel){
      return true;
    }
    return OfficeStore.officeViewModel.showAppointmentPages;
  }
}
