





































import { Component, Vue } from 'vue-property-decorator';
import {DayOfWeek, TreatmentViewModel} from "@/api/generated-api";
import {treatmentClient} from "@/api/clients";
import {getOrDaysOfWeek} from "@/utilities/helper";
import {OfficeStore} from "@/store/OfficeStore";

@Component({
  components: {
  },
})
export default class TreatmentList extends Vue {
  treatments:TreatmentViewModel[]=[];
  showHint:boolean = false;

  get loading(){
    return OfficeStore.routerLoading;
  }

  set loading(data: boolean){
    OfficeStore.setIsRouterLoading(data);
  }

  created(){
    this.loading=true;
  }

  async mounted(){
    try{
      this.loading=true;
      await this.loadData();
      this.showHint=true;
    }finally{
      this.loading=false;
    }
  }

  async loadData(){
    this.treatments = await treatmentClient.getViewModels(null,null, null, null);
  }

  async remove(id: number){
    try{
      this.loading=true;
      await treatmentClient.delete(id);
      await this.loadData();
      await OfficeStore.reloadOfficeViewModel();
    }finally{
      this.loading=false;
    }
  }
}
