
































































































































































import { Component, Vue } from 'vue-property-decorator';
import {OfficeRegisterModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";

@Component({
  components: {
  },
})
export default class Landing extends Vue {
  private registerModel : OfficeRegisterModel = OfficeRegisterModel.fromJS({});
  loading=false;

  async submit(){
    this.loading = true;
    try{
      await officeClient.register(this.registerModel);
      await this.$router.push({name: 'ConfirmAccountNeeded'});
    }finally{
      this.loading = false;
    }
  }
}
