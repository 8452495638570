


































































import { Component, Vue } from 'vue-property-decorator';
import OrSlideIn from "@/components/basic/OrSlideIn.vue";
import {DateTime} from "luxon";
import {AppointmentEditModel, TreatmentHourDayViewModel} from "@/api/generated-api";
import {appointmentClient, patientAppointmentClient, treatmentHourDayClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";

@Component({
  components: {
  },
})
export default class PatientAppointmentEdit extends Vue {
  treatmentHourDayId: number = 0;
  datetime: DateTime = DateTime.now();
  treatmentHourDay: TreatmentHourDayViewModel = new TreatmentHourDayViewModel();
  editModel: AppointmentEditModel = new AppointmentEditModel();
  loading: boolean = false;
  loadingTrans = false;
  guid: string = '';

  async created(){
    this.guid = this.$route.params.guid;
    this.treatmentHourDayId = parseInt(this.$route.params.treatmentHourDayId);
    this.datetime = DateTime.fromISO(this.$route.params.datetime);
  }

  async mounted(){
    this.openSlideIn();
    try{
      this.loading = true;
      this.treatmentHourDay = await patientAppointmentClient.getTreatmentHourDayById(this.guid, this.treatmentHourDayId);
      this.editModel = await patientAppointmentClient.getNewAppointmentEditModel(this.datetime.toFormat('yyyy-MM-dd HH:mm:ss'), this.treatmentHourDayId);
    }finally{
      this.loading = false;
    }
  }

  get treatmentName(){
    return this.treatmentHourDay.treatment?.name;
  }

  get calendarName(){
    return this.treatmentHourDay.calendar?.name;
  }

  async submit(){
    try{
      this.loadingTrans = true;
      let id = 0;
      if(this.treatmentHourDay.treatment?.appointmentMustBeConfirmed){
        id = await patientAppointmentClient.insertAppointmentNotConfirmed(this.guid, this.editModel);
      }else{
        id = await patientAppointmentClient.insertAppointmentConfirmed(this.guid, this.editModel);
      }
      notifySuccess("Termin gebucht")
      setTimeout(()=>{this.$router.push({ name: 'AppointmentConfirm',
        params: {id: id.toString(), guid: this.guid}})},1000);
    }finally{
      this.loadingTrans = false;
    }
  }

  openSlideIn() {
    (this.$refs.slideIn as OrSlideIn).open();
  }

  closeSlideIn() {
    (this.$refs.slideIn as OrSlideIn).close();
  }

  slideOutClosed() {
    this.$router.back();
  }

}
