import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from '@/store';
import {AppointmentViewModel, OfficeViewModel} from "@/api/generated-api";
import {DateTime} from "luxon";
import {officeClient} from "@/api/clients";
import Office from "@/views/Office/Office.vue";
import {getColorClasses} from "@/utilities/helper";

@Module({ dynamic: true, store, name: 'OfficeModule', namespaced: true })
export default class OfficeModule extends VuexModule {

    isLoggedIn: boolean = false;
    todayDt: DateTime = DateTime.now();
    routerLoading: boolean = true;

    @Mutation
    setIsRouterLoading(data: boolean){
        this.routerLoading = data;
    }

    officeViewModel: OfficeViewModel = OfficeViewModel.fromJS({});

    get officeFullySetup(){
        return this.officeViewModel.showAppointmentPages && this.officeViewModel.showCalendarPage;
    }

    get nextUnusedColor(){
        let colorClasses = getColorClasses();
        let usedColors = this.officeViewModel?.treatments?.map(x=>x.colorClass) ?? [];

        let res = colorClasses[0];

        res = colorClasses.filter(x=>!usedColors.some(y=>y==x))?.[0] ?? res;

        return res;
    }

    @Mutation
    setIsLoggedIn(data: boolean){
        this.isLoggedIn = data;
    }

    @Mutation
    setTodayDt(dt: string){
        this.todayDt = DateTime.fromISO(dt);
    }

    @Mutation
    setOfficeViewModel(data: OfficeViewModel){
        this.officeViewModel = data;
    }

    @Action
    async reloadOfficeViewModel(){
        this.setOfficeViewModel(await officeClient.getViewModel());
    }

    @Action
    async loadTodayDt(){
        this.setTodayDt(await officeClient.getOfficeToday())
    }
}

export const OfficeStore = getModule(OfficeModule);
