




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  ceilTimeToHour,
  floorTimeToHour,
  getDurationMinutesFromTimes,
  getMinutesFromTime,
  getOrDaysOfWeek,
  getTimeFromMinutes
} from "@/utilities/helper";
import {DayOfWeek, TreatmentHourDayEditModel} from "@/api/generated-api";

interface TreatmentHourDayAppointment{
  EditModel: TreatmentHourDayEditModel;
  Time: string;
  Top: number;
}

@Component({
  components: {
  },
})
export default class PatientSelectorPreview extends Vue {
  @Prop()treatmentHourDays!: TreatmentHourDayEditModel[];
  @Prop()durationPerPatient!: number;
  @Prop()patientsPerTreatment!:number;

  get boxHeight(){
    return 28;
  }

  get daysOfWeek(){
    return getOrDaysOfWeek();
  }

  get weekMinTime(){
    let resultDt = getMinutesFromTime('23:59');
    let data = this.treatmentHourDays.map(x=>getMinutesFromTime(floorTimeToHour(x.startTime!, this.durationPerPatient)));

    data.forEach(x=>{
      if(x<resultDt){
        resultDt = x;
      }
    });

    return resultDt;
  }

  get weekMaxTime(){
    let resultDt = getMinutesFromTime('00:00');
    let data = this.treatmentHourDays.map(x=>getMinutesFromTime(ceilTimeToHour(x.endTime!, this.durationPerPatient)));

    data.forEach(x=>{
      if(x>resultDt){
        resultDt = x;
      }
    });

    return resultDt;
  }

  get timeGrid(){
    let result: string[]=[];
    for(let i = this.weekMinTime; i < this.weekMaxTime; i=i+this.durationPerPatient){
      result.push(getTimeFromMinutes(i));
    }
    return result;
  }

  getTreatmentListPerDay(dayOfWeek: DayOfWeek){
    let data = this.treatmentHourDays.filter(y=>y.dayOfWeek == dayOfWeek);

    let res: TreatmentHourDayAppointment[] = [];

    data.forEach(x=>{
      for(let i = getMinutesFromTime(x.startTime!); i < getMinutesFromTime(x.endTime!); i=i+this.durationPerPatient){
        if(i+this.durationPerPatient <= getMinutesFromTime(x.endTime!)){

          let treatmentTime = i;
          let startTime = this.weekMinTime;

          let diff = Math.round((treatmentTime - startTime) / this.durationPerPatient);

          let item: TreatmentHourDayAppointment = {EditModel: x, Time: getTimeFromMinutes(i), Top: diff*this.boxHeight}
          res.push(item);
        }
      }
    });

    return res;
  }
}
