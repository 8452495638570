


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import MainMenu from "@/layout/MainMenu.vue";
import Sidebar from "@/layout/Sidebar.vue";
import {OfficeStore} from "@/store/OfficeStore";
import {CalendarOfficeViewModel} from "@/api/generated-api";

@Component({
  components: {
    MainMenu, Sidebar
  },
})
export default class DefaultLayout extends Vue {

  get calendars(){
    return OfficeStore.officeViewModel?.calendars ?? [];
  }

  getQueryFilterVal(cal: CalendarOfficeViewModel){
    if(cal.noOfPendingAppointments){
      return 'pending';
    }

    return 'all';
  }

  get isDevMode(){
    return process.env.NODE_ENV === 'development';
  }

}
