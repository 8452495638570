









import { Component, Vue } from 'vue-property-decorator';
import {OfficeConfirmEmailModel} from "@/api/generated-api";
import {officeClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";

@Component({
  components: {
  },
})
export default class ConfirmAccount extends Vue {
  loading:boolean = true;
  confirmModel: OfficeConfirmEmailModel = OfficeConfirmEmailModel.fromJS({});

  created(){
    this.confirmModel.email = (this.$route.query.email ?? '') as string;
    this.confirmModel.token = (this.$route.query.token ?? '') as string;
  }

  async mounted(){
    try{
      await officeClient.confirmEmail(this.confirmModel);
      notifySuccess('Email bestätigt!')
      setTimeout(()=>{this.$router.push({name: 'Login'});},2000);
    }catch{
      this.loading=false;
    }
  }
}
