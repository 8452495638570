





































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {DayOfWeek, TreatmentHourDayEditModel, TreatmentHourEditModel, TreatmentViewModel} from "@/api/generated-api";
import {getDurationMinutesFromTimes, getMinutesFromTime, getTimeFromMinutes} from "@/utilities/helper";
import {notifyWarning} from "@/utilities/notification";

@Component({
  components: {
  },
})
export default class TreatmentHourDayTimeEdit extends Vue {
  @Prop()days!: TreatmentHourDayEditModel[];
  @Prop()dayName!: string;
  @Prop()treatments!: TreatmentViewModel[];

  getVeeErrorTimeOverlap(time: string){
    let res = false;
    this.days.forEach(x=>{

      let currentTime = getMinutesFromTime(time);

      let otherTimeFrom = getMinutesFromTime(x.startTime!);
      let otherTimeTo = getMinutesFromTime(x.endTime!);

      if(otherTimeTo > currentTime && otherTimeFrom < currentTime){
        res = true;
      }

      if(otherTimeFrom < currentTime && otherTimeTo > currentTime){
        res = true;
      }
    })

    return res ? 'time-overlap' : '';
  }

  getVeeErrorWrongBlock(day: TreatmentHourDayEditModel, time:string){

    let overlapError = this.getVeeErrorTimeOverlap(time);
    if(overlapError){
      return overlapError;
    }

    let duration = this.getCurrentDuration(day);

    let timeFrom = getMinutesFromTime(day.startTime!);
    let timeTo = getMinutesFromTime(day.endTime!);

    let blockDuration = timeTo - timeFrom;

    if(blockDuration<duration){
      return  {'min-duration' : duration};
    }

    let rest = blockDuration%duration;

    if(rest>0){
      if(rest*2 > duration){
        return {'block-duration-short': duration-rest};
      }
      return {'block-duration-long': rest};
    }
  }

  getCurrentDuration(day: TreatmentHourDayEditModel){
    return this.treatments.find(x=>x.id == day.treatmentId)?.durationMinutes!;
  }

  @Emit('remove')
  remove(day: TreatmentHourDayEditModel){
    return day;
  }

  @Emit('add')
  add(){
    return;
  }
}
