






















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class MainMenuItemLink extends Vue {
  @Prop()icon!: string;
  @Prop()small!: boolean;
  @Prop()active!: boolean;
  @Prop()submenu!: boolean;
  @Prop()visible!: boolean;

  get isSubmenu(){
    return this.submenu !== undefined;
  }

  get isSmall(){
    return this.small !== undefined;
  }
}
