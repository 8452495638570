



























import { Component, Vue } from 'vue-property-decorator';
import {officeClient} from "@/api/clients";
import {notifySuccess} from "@/utilities/notification";

@Component({
  components: {
  },
})
export default class ForgotPassword extends Vue {
  loading: boolean = false;
  email: string = '';

  async submit(){
    try{
      this.loading =true;
      await officeClient.forgotPassword(this.email);
      await this.$router.push({name: 'PasswordResetSent'})
    }finally{
      this.loading=false;
    }

  }

}
